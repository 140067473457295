import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Support = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="max-w-7xl mx-auto mb-11">
        <div
          className="grid grid-cols-1 items-center"
          data-aos="fade-up"
          data-aos-duration="3000"
        >
          <p className="text-center text-[#ececfb] font-Inter text-[14px] lg:w-1/3 mx-auto">
            {"If you require any assistance, please feel free to contact us at "}
            <a className="text-decoration-line: underline text-[#ffffffc2]" href="mailto:support@justpush.io">support@justpush.io</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default Support;
